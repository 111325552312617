<template>
  <div id="app" class="pb-16 lg:pb-0 overflow-x-hidden min-h-screen relative">
    <NotificationsPopupContainer />
    <TheNavbar class="h-16 lg:h-20 sticky top-0 px-2 sm:px-4 md:px-8 z-navbar" />

    <EstimateGasErrorDialog />

    <main class="h-full px-2 sm:px-4 md:px-8">
      <incorrect-network-banner v-if="incorrectNetwork"></incorrect-network-banner>

      <pp-fade-transition
        v-else
        hide-on-leave
        :key="`${$store.state.wallet.address}-${$store.state.wallet.networkId}`"
      >
        <router-view />
      </pp-fade-transition>
    </main>

    <the-bottom-status class="fixed bottom-0 w-full lg:hidden z-navbar"></the-bottom-status>
  </div>
</template>

<script>
  import TheNavbar from '@/components/singles/TheNavbar.vue'
  import { mapGetters } from 'vuex'
  import TheBottomStatus from '@/components/singles/TheBottomStatus.vue'
  import NotificationsPopupContainer from '@/components/notifications/NotificationPopup/NotificationsPopupContainer.vue'
  import EstimateGasErrorDialog from '@/components/dialogs/EstimateGasErrorDialog.vue'
  import networkEnv from '@/app-config/networks'
  import IncorrectNetworkBanner from '@/components/IncorrectNetworkBanner.vue'
  import { getTokens } from '@pendle/sdk'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'

  export default {
    components: {
      TheNavbar,
      TheBottomStatus,
      NotificationsPopupContainer,
      EstimateGasErrorDialog,
      IncorrectNetworkBanner,
    },
    mounted() {
      if (process.env.NODE_ENV === 'development') {
        window.vm = this
      }

      this.$app.state.slippage = localStorage.getItem('slippage') || '0.1'

      this.$store.dispatch('wallet/init')
    },
    watch: {
      '$app.state.slippage': {
        handler(val) {
          localStorage.setItem('slippage', val)
        },
      },
    },
    computed: {
      currentUrl() {
        return window.location.pathname
      },
      networkEnv() {
        return networkEnv
      },
      ...mapGetters('wallet', ['incorrectNetwork']),
    },
    methods: {
      reloadWindow() {
        window.location.reload()
      },
      fetchTraderJoeTokens() {
        new PromiseHandler(getTokens, this.traderJoeTokensState).execute()
      },
    },
    data() {
      return {
        traderJoeTokensState: createState({ response: [] }),
      }
    },
    provide() {
      return {
        traderJoeTokensState: this.traderJoeTokensState,
      }
    },
    created() {
      this.fetchTraderJoeTokens()
    },
  }
</script>
