<template>
  <span>{{ formattedTime }}</span>
</template>

<script>
  import Vue from 'vue'
  import { getDurationShortFormat } from '@/assets/helpers'

  export default Vue.extend({
    props: {
      value: { type: Date, required: true },
      locale: { type: String, default: 'en' },
      format: {
        type: String,
        default: 'human',
        validator: (val) => ['human', 'countdown'].includes(val),
      },
      significantUnits : { type: Number,  default: undefined },
      absolute: { type: Boolean, default: false },
    },
    computed: {
      humanTime() {
        const shortFormat = getDurationShortFormat({
          startTime: this.$app.state.time,
          endTime: this.value,
          significantUnits: 1,
        })

        const durationValue = shortFormat.replace(/[^\d.-]/g, '') // remove non-decimals
        const durationUnit = shortFormat.slice(-1)

        const getRelativeTimeFormatUnit = (shortFormatUnit) => {
          switch (shortFormatUnit) {
            case 's': return 'second'
            case 'm': return 'minute'
            case 'h': return 'hour'
            case 'd': return 'day'
            case 'o': return 'month'
            case 'y': return 'year'
          }
        }
        const time = new Intl.RelativeTimeFormat();
        return time
          .format(durationValue, getRelativeTimeFormatUnit(durationUnit))
          .replace('minute', 'min')
      },
      countdownTime() {
        return getDurationShortFormat({
          startTime: this.$app.state.time,
          endTime: this.value,
          significantUnits: this.significantUnits,
          absolute: this.absolute,
        })
      },
      formattedTime() {
        return this[`${this.format}Time`]
      },
    },
  })
</script>
