export default class QueryCollection extends Array {
  static primaryKey = 'id'

  static from(array) {
    return new QueryCollection(...array)
  }

  #whereFilter() {
    const handler = {
      0: () => true,
      1: arguments[0],
      2: defaultHandler,
    }

    const defaultHandler = (data) => {
      const key = arguments[0]
      const nestedValue = key.split('.').reduce((acc, nestedKey) => acc?.[nestedKey], data)
      const matcher = arguments[1]

      return typeof matcher === 'function'
        ? matcher(nestedValue)
        : [].concat(nestedValue).some((value) => [].concat(matcher).includes(value))
    }

    return handler[arguments.length] || defaultHandler
  }

  all() {
    return this
  }

  where() {
    return this.filter((data) => this.#whereFilter(...arguments)(data))
  }

  whereNot() {
    return this.filter((data) => !this.#whereFilter(...arguments)(data))
  }

  find(id) {
    return this.filter(
      (data) =>
        data[this.constructor.primaryKey].toString().toLowerCase() === id.toString().toLowerCase()
    )[0]
  }

  findBy() {
    return this.where(...arguments)[0]
  }

  first(n = undefined) {
    return n === undefined ? this[0] : this.slice().splice(0, n)
  }

  last(n = undefined) {
    return n === undefined
      ? this.slice().reverse()[0]
      : this.slice().reverse().splice(0, n).reverse()
  }

  toArray() {
    return [...this]
  }
}
