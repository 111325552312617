import BigNumberjs from 'bignumber.js'

import networkEnv from '@/app-config/networks'
import TokenAmount from '@/domains/entities/TokenAmount'

BigNumberjs.config({ EXPONENTIAL_AT: 100 })

export const toSentence = (strings: string[], join = 'and') => {
  const clone = [...strings]
  const lastString = clone.pop()
  return [clone.join(', '), lastString].filter((s) => s).join(` ${join} `)
}

export const activeTokens = (entries: TokenAmount[]) => entries.filter((entry) => entry.rawAmount())

//Formats wallet address by getting the first 6 and last 4 digits of ETH address
export const formatETHWalletAddress = (address: string): string => {
  if (!address) return ''

  return address.substring(0, 6) + '...' + address.substring(address.length - 4, address.length)
}

export const generateEtherscanTxUrl = (txHash: string): string => {
  if (networkEnv.VUE_APP_NETWORK_ID == 42) return `https://kovan.etherscan.io/tx/${txHash}`
  if (networkEnv.VUE_APP_NETWORK_ID == 43114) return `https://snowtrace.io/tx/${txHash}`

  return `https://etherscan.io/tx/${txHash}`
}

export const generateEtherscanAddressUrl = (address: string): string => {
  if (networkEnv.VUE_APP_NETWORK_ID == 42) return `https://kovan.etherscan.io/address/${address}`
  if (networkEnv.VUE_APP_NETWORK_ID == 43114) return `https://snowtrace.io/address/${address}`

  return `https://etherscan.io/address/${address}`
}

export const getDurationShortFormat = (option: {
  startTime: Date
  endTime: Date
  significantUnits?: number
  absolute: boolean
}): string => {
  const { startTime, endTime, significantUnits, absolute } = option
  const endTimeSecond = endTime.getTime() / 1000
  const startTimeSecond = startTime.getTime() / 1000
  const secondDifference = Math.abs(endTimeSecond - startTimeSecond)

  let days = secondDifference / (60 * 60 * 24)

  const years = Math.floor(days / 365)

  let months = Math.floor((days % 365) / 31)
  if (months < 2)  months = 0 // use days instead

  days = months
    ? Math.floor((days % 365.25) % 30.44) // not 100% accurate as months don't have a consistent number of days
    : Math.floor(days)

  const hours = Math.floor((secondDifference % (60 * 60 * 24)) / (60 * 60))
  const minutes = Math.floor((secondDifference % (60 * 60)) / 60)
  const seconds = Math.floor(secondDifference % 60)

  const duration = [
    { unit: 'y', value: years },
    { unit: 'mo', value: months },
    { unit: 'd', value: days },
    { unit: 'h', value: hours },
    { unit: 'm', value: minutes },
    { unit: 's', value: seconds },
  ]

  const trimDuration = (durationArr: Array<{ unit: string; value: number }>) => {
    const startIndex = durationArr.findIndex(({ value }) => value > 0)
    const endIndex = significantUnits ? startIndex + significantUnits : undefined
    return durationArr.slice(startIndex, endIndex)
  }

  const trimmedDuration = trimDuration(duration)

  const durationStr = trimmedDuration.map((val) => `${val.value}${val.unit}`).join(' ')

  const negativeSign = absolute || endTimeSecond > startTimeSecond ? '' : '-'
  return `${negativeSign}${durationStr}`
}

export const debounce = (func: any, wait: number, immediate = false) => {
  let timeout: any

  return function executedFunction(this: any) {
    // eslint-disable-next-line
    const context = this
    // eslint-disable-next-line
    const args = arguments

    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)

    if (callNow) func.apply(context, args)
  }
}

export const toDecimalPlace = (value: string, decimals = 2): string => {
  return new BigNumberjs(value).toFormat(decimals)
}

export const formatNumber = (value: string | number, options: {
  minDecimal?: number
  maxDecimal?: number
  space?: boolean
  lowercase?: boolean
  humanize?: boolean
  currency?: string
}): string => {
  const unitMap = [
    { value: 1_000_000_000_000_000, symbol: 'Q' },
    { value: 1_000_000_000_000, symbol: 'T' },
    { value: 1_000_000_000, symbol: 'B' },
    { value: 1_000_000, symbol: 'M' },
    { value: 1_000, symbol: 'K' },
  ]

  const { space, lowercase, currency, minDecimal = 0, maxDecimal, humanize = true } = options || {}
  const defaultUnit = { value: 1, symbol: '' }
  const defaultValue = (value || 0).toString()
  const defaultMaxDecimal = Number(value) > 0 ? 2 : 5 // examples: 1.32, 112.12k, 0.00234, 0.53282

  const unit = humanize
    ? unitMap.find(({ value: unitValue }) =>
        new BigNumberjs(defaultValue).div(new BigNumberjs(unitValue)).gte(new BigNumberjs(1))
      ) || defaultUnit
    : defaultUnit

  const amount = new BigNumberjs(defaultValue).div(new BigNumberjs(unit.value)).toFixed()
  const defaultFormatOptions = {
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: maxDecimal || defaultMaxDecimal,
  }

  const output = [
    new Intl.NumberFormat(
      'en-US',
      currency
        ? { style: 'currency', currency, ...defaultFormatOptions }
        : defaultFormatOptions
    ).format(Number(amount)),
    unit.symbol,
  ]
    .join(space ? ' ' : '')
    .trim()

  return output[lowercase ? 'toLowerCase' : 'toUpperCase']()
}
